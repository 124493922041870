
import Global from "./global";
require('isomorphic-fetch');

const Request = (url, config) => {
    return fetch(url, config)
        .then((res) => {
            if (!res.ok) {
                // 服务器异常返回
                throw Error('接口请求异常');
            }
            return res.json();
        })
        .then((data) => {

            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

// GET请求
export const get = (url) => {
    let requrl = Global.httpUrl + url;
    // let requrl = 'https://cqredcross.org.cn/' + url;
    return Request(requrl, { method: 'GET' });
};

// POST请求
export const post = (url, data) => {
    let requrl = Global.httpUrl + url;
    // let requrl = 'https://cqredcross.org.cn/' + url;
    // let requrl = 'https://tongcheng.honghukeji.net/admin/' + url;
    return Request(requrl, {
        method: 'POST',
        headers: {
            // 'content-type': 'application/json',
            'Content-Type': 'application/json;',
            "Accept": "application/json",
            'token': localStorage.getItem('honghuToken') || "",
        },
        body: JSON.stringify(data),
    });
};