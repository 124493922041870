import logo from './logo.svg';
import './App.css';
import {Dialog, Toast} from "antd-mobile";
import {useState} from "react";
import * as req  from "./request"
function App() {
    const [contact,setConcat]=useState(null);
    const [phone,setPhone]=useState(null);
    const [content,setContent]=useState(null)
    const sub=()=>{
        if(contact==null || contact=="")
        {
            Toast.show({
                content:"请输入姓名"
            })
            return;
        }
        if(phone==null || phone=="")
        {
            Toast.show({
                content:"请输入电话"
            })
            return;
        }
        if(content==null || content=="")
        {
            Toast.show({
                content:"请输入充电宝编号"
            })
            return;
        }
        let loading=Toast.show({
            icon: 'loading',
            content: '加载中…',
            duration:0
        })
        req.post('/api/home/joinUs', {
            contact,phone,content
        }).then(res => {
            loading.close();
            if (res.code == 1) {

                Dialog.alert({
                    content: '提交成功,工作人员稍后将与您取得联系',
                    onConfirm: () => {
                        window.location="";
                    },
                })
            }else{
                Dialog.alert({
                    content: res.msg,
                    onConfirm: () => {
                        console.log('Confirmed')
                    },
                })
            }
        })
        setTimeout(()=>{
            loading.close();
        },3000)
    }
  return (
    <div className="App">
        <div className={"box1"}>
            {/*<img className={"img"} src={require("./imgs/img1.png")}/>*/}
            <div className={"box"}>
                <div className="line line1">
                    <span className={"star"}>*</span>
                    <span className={"label"}>姓名</span>
                    <input onChange={(e)=>{
                        setConcat(e.target.value)
                    }} placeholder={"请输入姓名"} className={"input"}  />
                </div>
                <div className="line">
                    <span className={"star"}>*</span>
                    <span className={"label"}>电话</span>
                    <input onChange={(e)=>{
                        setPhone(e.target.value)
                    }} placeholder={"请输入联系电话"} className={"input"}  />
                </div>
                <div className="line">
                    <span className={"star"}>*</span>
                    <span className={"label"}>充电宝编号</span>
                    <input onChange={(e)=>{
                        setContent(e.target.value)
                    }} placeholder={"请输入充电宝编号"} className={"input"}  />
                </div>
                <div onClick={()=>{
                    sub();
                }} className={"btn"}>
                    马上加盟  立即赚钱
                    <span className={"tips"}>
                        前景好、品牌大、高分成
                    </span>
                </div>
            </div>
        </div>
        <div>
            <img className={"img img2"} src={require("./imgs/img2.png")}/>
        </div>

        <img className={"img img3"} src={require("./imgs/img3.png")}/>
        <img className={"img img4"} src={require("./imgs/img4.png")}/>
        <div className={"bottom"}>
            深圳市银尘科技有限公司
        </div>
    </div>
  );
}

export default App;
